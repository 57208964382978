.explore-details {
  .data {
    display: flex;
    flex-direction: column;

    .cs-single_product_head h2 {
      font-size: 32px;
      font-weight: normal;
    }

    .cs-card_like {
      background: #3772FF1A;
    }

    .cs-author_card {
      padding: 0;

      h3 {
        color: #7E85A1;
        font-size: 18px;
        font-weight: normal;
      }
    }

    .line {
      height: 1px;
      background: linear-gradient(90deg, #1B2960 0%, transparent 98%);
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: -30px;
      width: calc(100% + 30px);
    }

    .price-p {
      font-size: 10px;
      color: #96A0CB;

      .cs-accent_color {
        font-size: 20px;
        color: #2B2B2B;
        font-weight: bold;
        margin-left: 20px;
      }
    }

    .cs-fade_tabs {
      flex: 1;
      display: flex;
      flex-direction: column;

      .cs-tab_links.cs-style1 {
        display: inline-flex;
        border-image-source: none;
        border-color: rgba(#7E85A1, .5);

        .active {
          & span {
            background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
            color: transparent;
            background-clip: text;

          }
        }

        a {
          font-weight: bold;
          color: rgba(#7E85A1, .5);

          &::after {
            background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          }
        }
      }

      .cs-tab_content {
        flex: 1;
        display: flex;

        .cs-tab {
          flex: 1;

          .cs-general_box_5 {
            height: 280px;
            overflow: auto;

            &::-webkit-scrollbar {
              width: 4px;
              height: 4px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
              background: #888;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
        }
      }
    }

    .cs-btn {
      border-radius: 10px;
      height: 58px;
      font-size: 24px;
    }
  }

  .detail-box {


    .cs-fade_tabs {
      border-radius: 10px;
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;

      .cs-tab_links.cs-style1 {
        border-image-source: none;
        border-color: rgba(#7E85A1, .5);

        li {
          flex: 1;
          text-align: center;

          &.active {
            & span {
              background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
              color: transparent;
              background-clip: text;

            }
          }
        }

        a {
          font-weight: bold;
          color: rgba(#7E85A1, .5);
          width: 100%;
          padding-top: 13px;
          padding-bottom: 13px;

          &::after {
            background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          }
        }
      }
    }

    #detailInfo {
      ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
          padding: 14px;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(#96A0CB, .2);
          }

          .address {
            color: #050023;
            font-size: 16px;
          }
        }
      }
    }

    #workInfo {
      padding: 10px;

      .user-info {
        padding: 12px;

        .avatar {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          margin-right: 16px;
        }

        .name {
          font-size: 14px;
        }

        .label {
          font-size: 20px;
          color: #2B2B2B;
        }
      }

      .title {
        margin: 10px 0;
      }

      .footer-input {
        background: rgba(#96A0CB, .2);
        border-radius: 6px;
        padding: 10px;
        margin-top: 10px;

        input {
          border: none;
          outline: none;
          background: transparent;
        }
      }
    }
  }

  .history-box {
    .content {
      border-radius: 10px;

      table {
        text-align: center;

        thead {


          th {
            border-top: none;
            font-weight: normal;
          }
        }

        tbody {
          color: #050023;
        }
      }
    }
  }
}