body,
html {
  height: 100%;
  overflow: auto;
}

.nft-application-form {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  padding-left: 35px;

  .cs-btn_lg {
    height: 56px;
  }

  .left {
    flex: 1;
    margin-right: 35px;

    .z-2 {
      z-index: 2;
    }

    .step {
      display: flex;
      margin: 20px 0;

      .item {
        flex: 3;
        display: flex;
        font-size: 12px;
        overflow: hidden;
        --color: #96A0CB;
        --bg: #CCD1DD;
        color: var(--color);
        justify-content: center;
        text-align: center;

        .box {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          line-height: normal;

          .content {
            margin-top: 5px;
          }
        }

        .line {
          width: 28px;
          height: 28px;
          background-color: var(--bg);
          border-radius: 12px;
          position: relative;
          justify-content: center;
          align-items: center;
          display: flex;
          color: white;
          font-weight: bold;

          &::after,
          &::before {
            content: '';
            display: block;
            width: 200px;
            height: 1px;
            margin-top: -.5px;
            position: absolute;
            top: 50%;
            background-color: var(--bg);
          }

          &::after {
            left: 100%;
          }

          &::before {
            right: 100%;
          }

        }

        &:first-child {
          flex: 2;
          justify-content: flex-start;

          .line::before {
            display: none;
          }
        }

        &:last-child {
          flex: 2;
          justify-content: flex-end;

          .line::after {
            display: none;
          }
        }

        &.active {
          --color: #3772FF;
          --bg: #3772FF;

          .line {
            background-color: var(--bg);

          }
        }
      }
    }

    .h-title {
      font-size: 20px;

      div div:first-child {
        font-weight: bold;
        color: #2b2b2b;
      }

      div div:last-child {
        font-family: "Pretendard";
      }

      &::after {
        margin-top: 3px;
        content: '';
        display: block;
        height: 1px;
        background: linear-gradient(90deg, #1B2960 29.5%, #FFFFFF 100%);
      }

    }

    .item {
      margin-bottom: 20px;

      .tab-nav {
        .tab-item {
          border: 1px solid #96A0CB;
          padding: 9px 12px;
          border-radius: 10px;
          cursor: pointer;
          font-weight: bold;
          font-family: "GmarketSans";

          &:not(:first-child) {
            margin-left: 10px;
          }

          &.active {
            color: #494F6A;
            border-color: #494F6A;
            background-color: white;

            svg {
              fill: #494F6A;
            }
          }

          svg {
            fill: #96A0CB;
          }
        }
      }

      .label {
        margin-bottom: 6px;
        font-size: 14px;
        color: #494F6A;
      }

      .textarea {
        width: 100%;
        border: 1px solid #96A0CB;
        padding: 9px 12px;
        border-radius: 10px;
        height: 150px;
        outline: none;
      }

      .upload-img {


        .img {
          width: 352px;
          height: 192px;
          border: 1px solid #494F6A;
          border-radius: 10px;
          padding: 1px;
          overflow: hidden;
          position: relative;
          background: url(/assets/img/upload-icon.svg) center/60px no-repeat, linear-gradient(rgba(#7E85A1, .1), rgba(#7E85A1, .1));

          img,
          .imgAndPdf {
            width: 100%;
            height: 100%;
            border-radius: inherit;
          }

          img {
            object-fit: cover;
          }
        }
      }

      .option {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 12px;
          color: #7E85A1;
        }

        .tip {
          font-size: 11px;
          line-height: 16px;
          color: #96A0CB;
        }
      }

      .code {
        color: white;
        background: #3772FF;
        border-radius: 8px;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
      }

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }

      .checkbox-group {
        color: #2B2B2B;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #96A0CB;

        .checked-item {
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }

          input {
            display: block;
            border: 1px solid #96A0CB;
            border-radius: 5px;
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            outline: none;
            padding: 0 10px;
          }
        }
      }
    }

    .big-upload {
      .img {
        width: 100%;
        height: 240px;
        border-radius: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        background: url(/assets/img/upload-icon.svg) center/60px no-repeat, linear-gradient(rgba(#7E85A1, .1), rgba(#7E85A1, .1));

        img {
          width: 100%;
          height: 100%;
        }

      }

      .title {
        font-size: 12px;
        color: #7E85A1;
        margin-top: 8px;
      }

      .tip {
        font-size: 11px;
        color: #96A0CB;
      }
    }

    .upload-btn {
      border: none;
      border-radius: 10px;
      color: white;
      background-color: #494F6A;
      padding: 4px 20px;
    }

    .page-disabled {

      .input-box,
      .b-select,
      .tab-item,
      .option .upload-btn,
      .big-upload .upload-btn,
      .form-check-input,
      .imgs-list .upload-box,
      .imgs-list .item:not(.has),
      .checkbox-group {
        pointer-events: none;
        background-color: rgb(245, 245, 245);
      }

      .option .pointer,
      .big-upload .pointer {
        display: none !important;
      }
    }

    .imgs-list {
      width: 570px;
      border-radius: 10px;
      background-color: rgba(#BABFD0, .3);
      text-align: center;
      padding: 18px;
      padding-bottom: 20px;
      margin: 20px auto;
      margin-top: 5px;

      .upload-box {
        border-radius: 4px;
        padding: 12px;
        border: 1px dashed #7E85A1;
        font-size: 12px;
        position: relative;

        .btn {
          background: #494F6A;
          border-radius: 50px;
          font-size: 10px;
          padding: 4px 10px;
          color: white;
          line-height: normal;
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
        }
      }

      .imgs {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 15px;
        width: 535px;
        height: 535px;

        .item {
          width: 167px;
          height: 167px;
          background: white;
          border-radius: 4px;
          background: url(/assets/img/add.svg) center/30px no-repeat, linear-gradient(white, white);
          overflow: hidden;
          margin-bottom: 0;
          position: relative;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }

          .check {
            position: absolute;
            top: 6px;
            right: 6px;
          }
        }
      }

      .slick-arrow {
        width: 35px;
        height: 35px;
        box-shadow: 0px 0px 12px 0px #8B9AD04D;
        border-radius: 50%;
        background-color: white;
        opacity: 1;
        color: black;
        display: flex;
        align-items: center;

        &::after {
          position: static;
        }

        &.slick-next {
          right: -8px;
        }

        &.slick-prev {
          left: -8px;
        }

        &.slick-disabled {
          opacity: 0;
        }
      }

      .slick-dots {
        bottom: -14px;

        li {
          width: 6px;
          height: 6px;

          button {
            height: 6px;
            background-color: #96A0CB;
            opacity: 1;
          }

          &.slick-active {
            width: 6px;
            height: 6px;

            button {
              background-color: #0479F7;
            }
          }
        }
      }

    }
  }

  .right {
    position: sticky;
    top: 100px;
    width: 420px;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px 0px #8B9AD04D;
    background-color: white;
    padding: 14px;
    border-radius: 10px;

    .img {
      height: 393px;
      border-radius: 10px;
      margin: 6px 0;
      width: 100%;
      object-fit: cover;
    }

    .title {
      font-size: 28px;
      color: #050023;
      padding: 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(#96A0CB, .2);
    }

    .user {
      .avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .name {
        font-size: 20px;
        color: #494F6A;
      }
    }

    .num {
      font-size: 24px;
      color: #2B2B2B;
    }

    .unit {
      font-size: 24px;
      color: transparent;
      background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
      background-clip: text;
    }
  }

}

@media screen and (max-width:991px) {

  .nft-application-form {
    display: block;
    padding-left: 0;
  }

  .left {
    margin-right: 0 !important;

    .imgs-list {
      width: 100% !important;


      .imgs {
        width: 100% !important;

        aspect-ratio: 1;
        height: auto !important;

        .item {
          width: 100% !important;
          height: 80px;

          @supports (aspect-ratio: 1) {
            aspect-ratio: 1;
            height: auto !important;
          }

        }
      }
    }
  }

  .right {
    position: static;
    margin: 40px auto;
    width: auto !important;
    margin-left: 0;
    margin-right: 0;
  }
}