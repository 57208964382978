/* 底部样式 */
.cs-footer1 {
  background-image: url(../img/home-banner1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 408px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    bottom: -50%;
    left: 5em;
    width: 301px;
    height: 326px;
    background-image: url(../img/home-banner2.jpg);
    background-size: contain;
    background-repeat: no-repeat;

  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  h3 {
    font-family: Gmarket Sans;
    font-size: 40px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: -0.04em;
    text-align: left;
    color: white;
  }

  .footer-btn {
    border: 1px solid white;
    border-radius: 80px;
    background: linear-gradient(225deg, rgb(35, 77, 101) 14.1%, rgb(26, 25, 112) 84.42%);
    color: white;
    font-size: 24px;
    padding: 15px 28px;
  }

  @media screen and (max-width: 991px) {
    background:
      url(../img/home/mask_group.png) no-repeat top left,
      url(../img/home/CK_td06090000413_1.png) no-repeat right bottom/50%,
      url(../img/home/Rectangle_4.png) no-repeat center /cover;

    h3 {
      text-align: center;
      font-size: 40px;
    }

    &::after {
      background-image: url(../img/home/Asset_12_4.png);
      width: 161px;
      height: 174px;
      bottom: -20%;
      left: 25px;
    }

    .footer-btn {
      display: block;
      margin: 0 auto;
    }

  }
}



.cs-footer {
  // background: url(../img/footerbg-svg.png) center top/100% 506px no-repeat, #050023;
  background-color: #050023;
  color: white;
  overflow: hidden;
  padding: 80px 0;

  .line {
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 2.99%, rgba(255, 255, 255, 0.3) 17.41%, rgba(255, 255, 255, 0.3) 82.95%, rgba(255, 255, 255, 0) 100%);
  }

  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-col {
    flex-direction: column;
  }

  .top {
    padding-top: 108px;
    padding-bottom: 180px;

    max-width: 900px;
    margin: 0 auto;

    h3 {
      font-family: Gmarket Sans;
      font-size: 40px;
      font-weight: 400;
      line-height: 54px;
      letter-spacing: -0.04em;
      text-align: left;
      color: white;
    }

    .footer-btn {
      border: 1px solid white;
      border-radius: 80px;
      background: linear-gradient(225deg, rgb(35, 77, 101) 14.1%, rgb(26, 25, 112) 84.42%);
      color: white;
      font-size: 24px;
      padding: 15px 28px;
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tip {
      font-size: 12px;
      color: #494F6A;
    }
  }




  .footer-nav-list {
    justify-content: end;
    font-size: 15px;
    margin-top: 20px;
    position: relative;
    gap: 70px;

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 150%;
      top: 2.2em;
      left: 0;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, .7) 20%);
      ;
    }

    .nav-group {
      list-style: none;

      font-size: 12px;
      color: #cccccc;

      .title {
        color: white;
        font-size: 15px;
        height: 2em;
        margin-bottom: 10px;
        font-weight: normal;
      }

      li {
        padding: 2px;
        font-weight: 300;
      }
    }
  }

  .footer-flex {
    max-width: 1130px;
    margin: 0 auto;
  }

  @media (max-width:768px) {
    .footer-flex {
      display: block;
      text-align: center;
    }

    .footer-nav-list {
      display: block;
      gap: 10px;

      &::after {
        display: none;
      }
    }

    .cs-footer1 {
      background-image: url(../img/home-banner11.jpg);
    }

    .cs-footer1 .container .row2 {
      margin: auto
    }

    .footer-flex .logo img {
      width: 50%;
      margin: 0 auto 20px auto !Important;
    }

    h3 {
      font-size: 30px !Important;
    }
  }

  @media (max-width:950px) {


    .footer-nav-list {
      gap: 10px;
    }
  }
}



@media (max-width:768px) {
  .cs-footer1 h3 {
    font-size: 30px !important;
  }

  .cs-footer1 .row2 {
    margin: auto
  }
}