@import "@/assets/css/plugins/fontawesome.min.css";
@import "@/assets/font/pretendard.css";
@import "@/assets/font/GmarketSans.css";
@import "@/assets/font/pretendard-subset.css";
@import "@/assets/css/plugins/bootstrap.min.css";
@import "@/assets/css/plugins/slick.css";
@import "@/assets/css/style.css";
@import "@/assets/css/about.scss";

body,
html {
  font-family: "Pretendard";
  background-color: #f7fafc;
}
.GmarketSans {
  font-family: "GmarketSans" !important;
  /* letter-spacing: -0.05em; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GmarketSans";
  font-weight: 500;
}
.weight300 {
  font-weight: 300 !important;
}
.weight400 {
  font-weight: 400 !important;
}
.weight500 {
  font-weight: 500 !important;
}
.weight600 {
  font-weight: 600 !important;
}
.weight700 {
  font-weight: 700 !important;
}

.cs-form-item_field {
  display: flex;
  align-items: center;
}
.cs-form_field {
  background-color: white;
}

.cs-form-item_field input {
  border: none;
  outline: none;
  background: none;
  color: inherit;
  flex: 1;
  min-width: auto;
}

.cs-form-item_must {
  color: #fc466b;
}

.cs-form-item_field input::placeholder {
  color: rgba(115, 122, 153, 0.5);
}

input[type="date"][value=""]:before {
  content: attr(placeholder);
  color: rgba(115, 122, 153, 0.5);
  float: left;
  pointer-events: none;
}

input[type="date"][value=""]::-webkit-datetime-edit {
  display: none;
}

.cs-form-item input::-webkit-calendar-picker-indicator {
  background-image: url("@/assets/svg/date.svg");
  margin-left: auto;
}
.text-ellipsis {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 用省略号表示溢出内容 */
}

.cs-bid_card1 .cs-title_title {
  font-size: 32px;
}

.idea-container p,
.idea-container ul {
  margin-bottom: 10px;
}

/* 首页顶部 */
.cs-hero.cs-style4 .cs-hero_text {
  background: linear-gradient(
    90.41deg,
    rgba(255, 255, 255, 0.1) -0.38%,
    rgba(255, 255, 255, 0) 99.66%
  );
}
.cs-hero.cs-style4 .cs-hero_text .cs-hero_btn.cs-style1.cs-color3 {
  background: linear-gradient(
    103.15deg,
    #0479f7 0%,
    #5a40ff 50.14%,
    #2600bd 98.5%
  );
}
.cs-header_icon_btn {
  background: transparent;
}

.cs-card.cs-style4 .cs-card_info {
  margin-top: -12px;
}
.cs-card.cs-style4 .cs-avatar {
  align-items: end;
}
.cs-card.cs-style4 .cs-card_price {
  font-size: 12px;
  text-align: end;
}
.cs-card.cs-style4 .cs-card_title {
  font-weight: normal;
}
.cs-card.cs-style4 .cs-card_price b {
  font-size: 26px;
  font-weight: normal;
}
.cs-card.cs-style4 .cs-avatar span {
  font-size: 12px;
  color: #8b9ad0;
}
.gradient-btn {
  color: transparent;
  border: 1px solid #5a40ff;
  background: linear-gradient(103.15deg, #4da3ff 0%, #6f58ff 82.5%);
  background-clip: text;
  border-radius: 100px;
  padding: 2px 10px;
  font-size: 13px;
}
.cs-card.cs-style5 .cs-card_img {
  border-radius: 0;
  width: 64px;
  height: 80px;
  margin-top: -15px;
  margin-left: -15px;
  margin-bottom: -15px;
  display: block;
}

.relative {
  position: relative;
}
.z1 {
  z-index: 1;
}

.cs-filter_toggle_body .check {
  margin-right: 6px;
}

.top-nav-tip {
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  align-self: self-start;
  font-size: 12px;
  position: relative;
  top: -50px;
}
.top-nav-tip span {
  color: white;
}
.bg-text {
  background: linear-gradient(90deg, #242424 36.5%, #3772ff 100%);
  background-clip: text;
  color: transparent;
}
.bg-text span {
  color: transparent;
}
.bg-text2 {
  background: linear-gradient(
    103.15deg,
    #0479f7 0%,
    #5a40ff 50.14%,
    #2600bd 98.5%
  );
  background-clip: text;
  color: transparent;
}
.bg-text2 span {
  color: transparent;
}
/* 针对 WebKit 浏览器 (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* 滚动条宽度 */
  height: 6px; /* 滚动条高度 */
}

::-webkit-scrollbar-track {
  background: transparent; /* 滚动条轨道背景 */
  border-radius: 10px; /* 轨道圆角 */
}

::-webkit-scrollbar-thumb {
  background: #96a0cb; /* 滚动条滑块颜色 */
  border-radius: 10px; /* 滑块圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background: #96a0cb; /* 滑块悬停时的颜色 */
}
.cs-form_field_wrap {
  background: linear-gradient(
    103.15deg,
    #0479f7 0%,
    #5a40ff 50.14%,
    #2600bd 98.5%
  );
  /* padding: 0; */
}

.cs-form_field.cs-field_sm {
  font-size: 14px;
  min-height: auto;
}

.form-switch .form-check-input {
  border-radius: 9px;
  height: 24px;
  width: 48px;
  background-image: url(~@/assets/img/round.svg);
}

.cs-toggle_body.cs-user_info_box {
  border-top-width: 0;
  box-shadow: 0px 0px 12px 0px #23116c33;
  border-radius: 10px;
  padding: 16px;
  width: 180px;
}
.cs-user_info_box .cs-nick_name {
  font-size: 16px;
  color: #2b2b2b;
  margin-bottom: 6px;
}
.cs-user_info_box .cs-user_name {
  font-size: 12px;
  color: #7e85a1;
}
.cs-user_info_box .balance-list {
  margin-top: 15px;
  margin-bottom: 15px;
}
.cs-user_info_box .balance-list .val {
  color: #3772ff;
  font-size: 15px;
}
.cs-user_info_box .balance-list .label {
  color: #96a0cb;
  font-size: 10px;
}

.cs-header_collspan_nav {
  border-top: 1px solid #96a0cb33;
  border-bottom: 1px solid #96a0cb33;
  color: #494f6a;
  margin-bottom: 10px !important;
  padding-top: 10px !important;
}
.cs-header_collspan_nav li {
  margin-bottom: 10px !important;
}
.cs-user_info_box a {
  font-weight: normal;
  font-size: 14px;
}

.cs-filter_sidebar {
  padding-top: 0;
}
.cs-btn.cs-style1.hollow {
  position: relative;
}
.cs-btn.cs-style1.gray {
  background: #babfd0;
}
.cs-btn.cs-style1.hollow span {
  position: relative;
  z-index: 2;
}
.cs-btn.cs-style1.hollow::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: inherit;
  border: 2px solid transparent;
  background: white;
  background-clip: content-box;
  opacity: 1;
}
.cs-btn.cs-style1.hollow:disabled,
.cs-btn.cs-style1.cs-btn_lg:disabled {
  background: rgba(245, 245, 245);
}
.cs-btn.cs-style1.hollow:disabled span,
.cs-btn.cs-style1.cs-btn_lg:disabled {
  color: #96a0cb;
}
.flex-1 {
  flex: 1;
}

.cs-card.cs-style4 .cs-avatar img {
  width: 40px;
  height: 40px;
  margin-top: -11px;
}
.cs-card.cs-style4 .cs-avatar span {
  font-size: 14px;
}
.tip-btn {
  color: #7e85a1;
  font-size: 16px;
  background: #96a0cb4d;
  border-radius: 3.62px;
  padding: 7px 18px;
  border: none;
}
.btn-primary {
  font-size: 14px;
  color: #ffffff;
  padding: 7px;
  min-width: 77px;
  border-radius: 100px;
  line-height: 16.96px;
  background: linear-gradient(
    103.15deg,
    #0479f7 0%,
    #5a40ff 50.14%,
    #2600bd 98.5%
  );
  border: none;
}

.btn-disabled {
  font-size: 14px;
  color: #7e85a1;
  padding: 7px;
  min-width: 77px;
  border-radius: 100px;
  line-height: 16.96px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  border: 1px solid #7e85a1;
}
.btn-danger {
  font-size: 14px;
  color: #ff4e4e;
  padding: 7px;
  min-width: 51px;
  border-radius: 100px;
  line-height: 16.96px;
  background: transparent;
  border: 1px solid #ff4e4e;
}
.w-120 {
  width: 120px;
}
.w-150 {
  width: 150px;
}
.w-180 {
  width: 180px;
}
.w-200 {
  width: 200px;
}
.logo_white-mo {
  display: none !important;
}

#app {
  overflow: auto !important;
  height: 100vh !important;
}

@media (max-width: 1080px) {
  .logo_white-pc {
    display: none !important;
  }
  .logo_white-mo {
    display: block !important;
    margin: auto;
  }
  .home-page .cs-hero.cs-style4 .cs-hero_text {
    text-align: center;
  }
  .home-page .cs-hero.cs-style4 .cs-hero_text .cs-hero_btns a {
    margin: auto;
  }
  .home-page .cs-hero.cs-style4 .cs-hero_text img {
    width: 60%;
  }
  .home-page section.cs-hero.cs-style4.cs-bg.cs-center {
    background-image: url("~@/assets/img/home-bannern.jpg") !important;
    background-size: cover !important;
  }
  a.cs-btn.cs-style1.wallter-user {
    max-width: 100px;
  }
}

@media (max-width: 500px) {
  .order .bg-text.fs-4.d-inline-block {
    width: 100%;
    text-align: right;
  }
  .d-flex.flex-column.justify-content-between.align-items-start.me-4 {
    margin-right: 0 !important;
  }
  .nft-application .order .img,
  .nft-application .order2 .img {
    width: 100% !important;
    height: auto !important;
  }
}
