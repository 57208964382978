body,
html {
  height: 100%;
  overflow: auto;
}

.idea-container {
  .content {
    max-width: 1154px;
    margin: 0 auto;
    column-gap: 20px;
    align-items: start;

    .left {
      flex: 1;
      border-radius: 10px;
      background: rgba(255, 255, 255, .7);
      padding: 30px;

      .idea-content {
        position: relative;
        padding-bottom: 400px;

        p {
          margin-bottom: 0;
          padding: 0;
        }

        b {
          padding: 0;
        }

        &::after {
          content: url(~@/assets/img/Group94.png);
          position: absolute;
          bottom: -120px;
          left: 50%;
          transform: translateX(-50%);
        }

        &.language_ko::after {
          content: url(~@/assets/img/Group94ko.png);
        }

        .idea-title {
          text-align: center;
          font-size: 36px;
          background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          display: inline-block;
          background-clip: text;
          color: transparent;
          margin-bottom: 0;
        }

        .idea-tip {
          background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          display: inline-block;
          background-clip: text;
          color: transparent;
          font-size: 24px;
        }

        .table {

          &>tr>td {
            border-top: none;
            vertical-align: text-top;
            padding: 0;
            padding-bottom: 6px;

            td {
              color: #494F6A;
            }
          }

          .label {
            white-space: nowrap;
            font-size: 14px;
            color: #7E85A1;
            position: relative;
            padding-right: 10px;

            &::before {
              content: "";
              display: inline-block;
              width: 6px;
              height: 6px;
              background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
              box-shadow: 0px 1px 6px 0px #9777C4A3;
              vertical-align: middle;
              margin-right: 10px;
              border-radius: 50px;
            }


          }

          th {
            font-size: 12px;
            font-weight: normal;
          }


          ul {
            list-style-type: disc;
            padding-left: 20px;
            color: #494F6A;
            padding-top: 0;
            padding-bottom: 0;
          }

          .tip {
            font-size: 14px;
            color: #7E85A1;
          }
        }
      }
    }

    .right {
      width: 484px;
      border-radius: 10px;
      background: rgba(255, 255, 255, .7);
      padding: 33px;
      position: sticky;
      top: 100px;

      .cs-section_title {
        background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
        background-clip: text;
        color: transparent;
      }

      .title {
        margin-bottom: 10px;

        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
          box-shadow: 0px 1px 6px 0px #9777C4A3;
          vertical-align: middle;
          margin-right: 10px;
          border-radius: 50px;
        }
      }

      .cs-form_field_wrap {
        background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
        border-radius: 10px;
      }

      .file-list-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cs-upload_btn {
          border-radius: 10px;
          background-color: #494F6A;

        }
      }

      .line {
        height: 1px;
        background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
        margin: 24px 0;
        opacity: .2;
      }

      .tip {
        font-size: 12px;
      }

      .cs-btn {
        width: 100%;
        border-radius: 10px;
        background-color: #494F6A;
      }
    }


  }

}

@media screen and (max-width:991px) {
  .idea-container {
    display: block;

    .left {
      overflow: auto;

      .idea-content {
        padding-bottom: 20px !important;

        .table {
          &>tr {
            display: grid;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    .right {
      position: static;
      margin: 40px auto;
      width: auto !important;
      margin-left: 0;
      margin-right: 0;
    }
  }

}