.about-container{
  background:linear-gradient(to bottom, #ffffff,#ffffff, transparent),url("@/assets/img/aboutbg.png") bottom right /cover no-repeat;
  margin-bottom: -100px;
  .about-content{
    .item{
      .baner{
        max-width: 40%;
      }
      @media screen and (max-width:991px) {
        .baner{
          display: none;
        }
      }
    }
    .item1{
      display: flex;
      justify-content: end;
      .icon1{
        width: 85px;
        height: 176px;
      }
      .con{
        max-width: 564px;
        margin-top: 20px;
        margin-left: 60px;
        margin-right: 60px;
        font-size: 20px;
        line-height: 1.4em;
        color: #505050;
        font-weight: normal;
        .title{
          font-size: 32px;
          font-weight: bold;
          color: #16626D;
          margin-bottom: 20px;
        }
      }
    }
    .item2{
      display: flex;
      margin-top: 120px;
      align-items: start;
      .list{
        margin-left: 40px;
        padding-top: 60px;
        .li{
          display: flex;
          color: #3E3E3E;
          font-size: 24px;
          line-height: normal;
          
          .title{
            color: #16626D;
            font-size: 28px;
            font-weight: bold;
            margin-right: 20px;
            padding-bottom: 100px;
            position: relative;
            display: block;
          
            &::after{
              content: '';
              display: block;
              height: 80px;
              width: 1px;
              background: #16626D;
              position: absolute;
              bottom: 10px;
              left: 30px;
            }
            &::before{
              content: '';
              display: block;
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background: #16626D;
              position: absolute;
              bottom: 10px;
              left: 26px;
            }
          }

          .txt{
            margin-top: 10px;
          }
          &:first-child{
            .title:first-child{
              font-size: 36px;
            }
          }
          &:last-of-type{
            .title::after,.title::before{
              display: none;
            }
          }
        }
      }
    }
    .item3{
      margin-top: 130px;
      margin-bottom: 200px;
      display: flex;
      justify-content: end;
      align-items: start;
      .con{
        padding-top: 60px;
        text-align: right;
        margin-right: 64px;
        line-height: normal;
        color: #3E3E3E;
        font-size: 24px;
        .title{
          color: #16626D;
          font-size: 36px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }
    }
  }
}