.panel {
  margin: 18px 0;
  border-radius: 10px;
  background-color: white;
  padding: 0 10px 10px;
  box-shadow: 0px 1px 10px 0px #0000BD0D;

  &>.title {
    padding: 14px 12px 12px;
    font-size: 12px;
    color: #7E85A1;
    line-height: 1;
    border-bottom: 1px solid rgba(#BABFD0, .5);
  }
}

.collapse-list {
  .item {
    margin: 12px 12px 0;

    .label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .icon {
        transition: all .3s;
      }
    }

    .content {
      padding-top: 10px;
      padding-left: 8px;
      overflow: hidden;
      max-height: 0;
      transition: all .3s;
    }

    &.active {
      .content {
        overflow: auto;
        max-height: 100px;
      }

      .icon {
        transform: rotateZ(180deg);
      }
    }
  }
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}