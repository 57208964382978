.login,
.register {
  &.login {
    .box {
      .left {
        background: url(~@/assets/img/loginbg.jpg) center center/cover no-repeat;
      }
    }
  }

  &.register {
    .box {
      .left {
        background: url(~@/assets/img/registerbg.jpg) center center/cover no-repeat;
      }
    }
  }

  .box {
    min-height: calc(100vh - 90px);
    display: flex;

    .left {
      flex: 1;
    }

    .right {
      width: 876px;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 76px;

      .cs-form_card {
        max-width: 480px;
        overflow: auto;

        .cs-form_card_in {
          max-height: 650px;
          overflow: auto;
          padding-right: 4px;
          padding-left: 4px;
        }

        .tab-nav {

          .tab-item {
            border: 1px solid #96A0CB;
            padding: 9px 12px;
            border-radius: 10px;
            cursor: pointer;

            &:not(:first-child) {
              margin-left: 10px;
            }

            &.active {
              color: #494F6A;
              border-color: #494F6A;

              svg {
                fill: #494F6A;
              }
            }

            svg {
              fill: #96A0CB;
            }
          }
        }

        .cs-form_title {
          margin-bottom: 8px;
        }

        .cs-form_tip {
          color: #2B2B2B;
        }

        .cs-form_label {
          margin-left: 0;
          color: #494F6A;
          font-size: 14px;
        }

        .cs-form_field_wrap {
          background: #96A0CB;
          border-radius: 10px;

          .code {
            background-color: #3772FF;
            color: white;
            font-size: 14px;
            padding: 8px 18px;
            border-radius: 8px;
            margin-right: -8px;
          }
        }

        .cs-btn {
          height: 56px;
          background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
          border-radius: 10px;
          font-size: 20px;
        }

        .google-login {
          background: transparent;
          border: 1px solid #96A0CB;
          color: #494F6A;
          font-size: 20px;

          &:hover {
            color: white;
          }
        }

        .cs-form_footer {
          font-size: 14px;
          color: #2B2B2B;
        }

        .line {
          border-bottom: 1px solid rgba(#7E85A1, .2);
        }
      }
    }
  }
}



@media (max-width: 900px) {

  .login.register .box .left,
  .register.register .box .left {
    display: none;
  }

  .login .box {
    width: 100%;
  }

  .login .box .right,
  .login .box .right,
  .register .box .right {
    width: 100% !important;
    margin: 0;
  }

  .login .box .right .cs-form_card,
  .register .box .right .cs-form_card {
    max-width: 100%;

  }
}

@media (max-width: 768px) {

  .login .box .right,
  .login .box .right,
  .register .box .right {
    padding: 0 20px;
  }
}