.contact-container {
  max-width: 1154px;
  padding: 0;

  .contact-flex {
    flex-wrap: wrap;
    column-gap: 20px;

    .cs-contact_box {
      flex: 1;
      box-shadow: none;
      padding: 40px;
    }

    .cs-contact_card_wrap {
      box-shadow: none;
      // width: 41.9%;
      flex: 1;
      display: flex;
      padding: 0;

      .cs-contact_card {
        flex: 1;
        flex-direction: column;

        .cs-contact_info:not(:last-child)::before {
          display: none;
        }

        .cs-contact_info {
          padding: 15px 55px;
        }

        .line {
          height: 1px;
          background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
          margin: 24px 0;
          opacity: .2;
          flex: none;
        }

        .cs-contact_text {
          font-size: 18px;
          color: #494F6A;
        }
      }
    }
  }

  .cs-section_title span {
    background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
    background-clip: text;
    color: transparent;
  }

  .cs-contact_title span {

    background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
    background-clip: text;
    color: transparent;
  }

  .section_subtitle {
    color: #494F6A;
  }

  .title {
    margin-bottom: 10px;

    &::before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
      box-shadow: 0px 1px 6px 0px #9777C4A3;
      vertical-align: middle;
      margin-right: 10px;
      border-radius: 50px;
    }
  }

  .cs-form_field_wrap {
    background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
    border-radius: 10px;
  }

  .cs-btn {
    width: 100%;
    border-radius: 10px;
    background-color: #494F6A;
  }
}