.nft-application {
  .topBtn {
    background: #494F6A;
    border-radius: 50px;
    font-size: 16px;
    color: white;
    height: 30px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
  }

  .cs-tab_links {
    border-image-source: none;
    border-color: rgba(#7E85A1, .5);

    li {
      flex: 1;
      text-align: center;

      a {
        width: 100%;
        color: #96A0CB;

        .dage {
          background: #96A0CB;
          font-size: 14px;
          border-radius: 50px;
          color: white;
          padding: 3px 8px;
          margin: 0 4px;
        }

      }

      &.active {
        .txt {
          background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          background-clip: text;
          color: transparent;
        }

        .dage {
          background-color: #3772FF;
        }
      }
    }
  }

  .order,
  .order2 {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 16px;

    .time {
      // width: 180px;
      margin-right: 10px;
      font-size: 16px;

      .year {
        color: #7E85A1;
      }

      .m {
        b {
          font-size: 24px;
          color: #2B2B2B;
          margin: 0 2px;
        }
      }
    }

    .img {
      width: 170px;
      height: 170px;
      border-radius: 10px;
      object-fit: cover;
    }



    .data {
      flex: 1;

      .top {
        margin-left: 22px;
        margin-bottom: 14px;

        .dage {
          background-color: #96A0CB33;
          border-radius: 3px;
          padding: 3px 10px;
          font-size: 13px;
          color: #96A0CB;
          line-height: normal;
          margin-right: 10px;
        }

        .title {
          font-size: 24px;
          color: #2B2B2B;
        }

        .bg-text {
          background-image: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
          margin-left: 10px;
        }

        .price {
          .bg-text {
            font-size: 24px;
          }
        }
      }

      .button {
        background: linear-gradient(103.15deg, #0479F7 0%, #5A40FF 50.14%, #2600BD 98.5%);
        border-radius: 50px;
        font-size: 16px;
        color: white;
        height: 30px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .line {
        height: 1px;
        background: linear-gradient(90deg, #2E313B 0%, rgba(46, 49, 59, 0) 100%);
      }

      .info {
        font-size: 14px;
        margin-left: 22px;
        margin-top: 12px;
        color: #494F6A;
        display: -webkit-box;
        /* 需要支持的浏览器 */
        -webkit-box-orient: vertical;
        /* 垂直排列 */
        -webkit-line-clamp: 3;
        /* 限制为三行 */
        overflow: hidden;
        /* 隐藏超出部分 */
        text-overflow: ellipsis;
      }

      .option {
        margin-left: 22px;
        margin-top: 12px;

        .red {
          font-size: 14px;
          color: #FF4E4E;
        }


      }
    }
  }

  @media screen and (max-width: 991px) {
    .cs-section_heading {
      font-size: 24px;
    }

    .cs-tab_links.cs-style1 a {
      font-size: 14px;
    }

    .topBtn {
      font-size: 12px;
      line-height: normal;
    }

    .order,
    .order2 {
      .img {
        margin-top: 10px;
      }

      .top {
        margin-left: 0 !important;
        margin-top: 10px;
      }
    }
  }
}



.mint-modal {
  .anticon.anticon-exclamation-circle {
    display: none;
  }

  .ant-modal-confirm-body {
    display: block;

    .ant-modal-confirm-paragraph {
      max-width: 100%;
    }
  }

  .box {
    padding: 26px 22px 15px;

    .h-title {
      font-size: 20px;

      &::after {
        margin-top: 3px;
        content: '';
        display: block;
        height: 1px;
        background: linear-gradient(90deg, #1B2960 29.5%, #FFFFFF 100%);
      }
    }

    .item {
      margin-bottom: 20px;

      .tab-nav {
        .tab-item {
          border: 1px solid #96A0CB;
          padding: 9px 12px;
          border-radius: 10px;
          cursor: pointer;
          font-weight: bold;
          display: none;
          gap: 10px;


          &.active {
            color: #494F6A;
            border-color: #494F6A;
            background-color: white;
            display: block;

            svg {
              fill: #494F6A;
            }
          }

          svg {
            fill: #96A0CB;
          }
        }
      }

      .label {
        margin-bottom: 6px;
        font-size: 14px;
        color: #494F6A;
      }

      .textarea {
        width: 100%;
        border: 1px solid #96A0CB;
        padding: 9px 12px;
        border-radius: 10px;
        height: 182px;
        outline: none;
      }

      .upload-img {


        .img {
          width: 192px;
          height: 192px;
          border: 1px solid #494F6A;
          border-radius: 10px;
          padding: 1px;
          overflow: hidden;
          position: relative;
          background: url(/assets/img/upload-icon.svg) center/60px no-repeat, linear-gradient(rgba(#7E85A1, .1), rgba(#7E85A1, .1));

          img,
          .imgAndPdf {
            width: 100%;
            height: 100%;
            border-radius: inherit;
          }

        }
      }

      .option {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 12px;
          color: #7E85A1;
        }

        .tip {
          font-size: 11px;
          color: #96A0CB;
        }
      }

      .code {
        color: white;
        background: #3772FF;
        border-radius: 8px;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
      }

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }

      .checkbox-group {
        color: #2B2B2B;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #96A0CB;

        .checked-item {
          margin-bottom: 8px;

          &:last-of-type {
            margin-bottom: 0;
          }

          input {
            display: block;
            border: 1px solid #96A0CB;
            border-radius: 5px;
            width: 100%;
            height: 30px;
            box-sizing: border-box;
            outline: none;
            padding: 0 10px;
          }
        }
      }
    }

    .imgs-list {
      width: 410px;
      border-radius: 10px;
      background-color: rgba(#BABFD0, .3);
      text-align: center;
      padding: 10px;
      padding-bottom: 20px;
      margin: 20px auto;
      margin-top: 5px;

      .upload-box {
        border-radius: 4px;
        padding: 12px;
        border: 1px dashed #7E85A1;
        font-size: 12px;
        position: relative;

        .btn {
          background: #494F6A;
          border-radius: 50px;
          font-size: 10px;
          padding: 4px 10px;
          color: white;
          line-height: normal;
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
        }
      }

      .imgs {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 12px;
        width: 390px;
        height: 390px;

        .item {
          width: 120px;
          height: 120px;
          background: white;
          border-radius: 4px;
          background: url(/assets/img/add.svg) center/30px no-repeat, linear-gradient(white, white);
          overflow: hidden;
          margin-bottom: 0;
          position: relative;

          img {
            display: block;
            width: 100%;
            height: 100%;
          }

          .check {
            position: absolute;
            top: 6px;
            right: 6px;
            display: none !important;
          }
        }
      }

      .slick-arrow {
        width: 35px;
        height: 35px;
        box-shadow: 0px 0px 12px 0px #8B9AD04D;
        border-radius: 50%;
        background-color: white;
        opacity: 1;
        color: black;
        display: flex;
        align-items: center;

        &::after {
          position: static;
        }

        &.slick-next {
          right: -8px;
        }

        &.slick-prev {
          left: -8px;
        }

        &.slick-disabled {
          opacity: 0;
        }
      }

      .slick-dots {
        bottom: -14px;

        li {
          width: 6px;
          height: 6px;

          button {
            height: 6px;
            background-color: #96A0CB;
            opacity: 1;
          }

          &.slick-active {
            width: 6px;
            height: 6px;

            button {
              background-color: #0479F7;
            }
          }
        }
      }

    }


  }

  .error-tip {
    border-radius: 20px;
    background-color: #F6F6F6;
    padding: 28px;
    font-size: 20px;

    ul {
      list-style-type: disc;
    }
  }

  .footer-btn-group {
    padding: 0 26px 30px;

    .hollow {
      background: #494F6A;
      color: #494F6A;
    }

    .cs-btn_lg {
      border-radius: 18px;
      height: 70px;
      font-size: 24px;
    }
  }
}


@media (max-width: 500px) {
  .nft-application .cs-tab_links {
    flex-wrap: nowrap;
  }

  .nft-application .cs-tab_links.cs-style1 a {
    display: flex;
    flex-direction: column;
  }

  .nft-application .order .data .button,
  .nft-application .order2 .data .button {
    font-size: 12px;
  }

  .nft-application .order .data .top .title,
  .nft-application .order2 .data .top .title {
    font-size: 16px;
  }

  .nft-application .order .data .top .dage,
  .nft-application .order2 .data .top .dage {
    font-size: 12px;
  }
}