.paging-btn-group {
  display: flex;
  align-items: center;
  column-gap: 5px;

  .first,
  .prev,
  .num,
  .next,
  .last {
    border: 1px solid #96A0CB80;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 13px;
    color: #96A0CB80;
    cursor: pointer;

    &:hover,
    &.active {
      background: #3772FF;
      color: white;

      svg {
        fill: white;
      }
    }
  }
}