.mine-box {
  .cs-cover_photo {
    border-radius: 0;
    height: 270px;

    .container {
      height: 100%;
      display: flex;
      justify-content: space-between;

      .nav-tip {
        margin-top: 20px;
        color: rgba(255, 255, 255, .6);
        display: flex;
        align-items: center;
        align-self: self-start;
        font-size: 12px;

        span {
          color: white;
        }
      }

      .cs-edit {
        margin: 20px;
        background: #FFFFFF33;
        border: none;
        padding: 6px 18px;
        border-radius: 10px;
        color: white;
        font-size: 14px;
        align-self: flex-end;

        .icon {
          vertical-align: middle;
          margin-right: 4px;
        }
      }
    }
  }

  .cs-profile_sidebar {
    padding: 0;
    margin-top: -100px;
  }

  .cs-profile_info {
    background: white;
    box-shadow: 0px 0px 12px 0px #8B9AD04D;
    position: relative;
    z-index: 1;
    padding: 28px;
    border-radius: 10px;

    .cs-profile_title {
      word-break: break-all;
    }

    .cs-profile_pic {
      margin-top: 0;
      width: 204px;
      height: 204px;
    }

    .line {
      height: 1px;
      margin: 12px 0 19px;
      background: linear-gradient(90deg, rgba(38, 0, 189, 0) 0%, #2600BD 20.18%, #2600BD 50%, #2600BD 80.06%, rgba(38, 0, 189, 0) 100%);
    }

    .cs-name {
      color: #494F6A;
    }

  }

  .cs-profile_nav {
    padding: 0px 20px;
    background: white;
    border-radius: 10px;

    li {
      margin-bottom: 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(#96A0CB, .2);
      }
    }

    a {
      background: transparent;
      border-radius: 0px;
      padding: 16px 20px;
      padding-right: 0;

      &.active,
      &:hover {
        color: #3772FF;
      }
    }
  }

  .cs-form_field_wrap {
    background: #96A0CB;
    border-radius: 10px;
  }

  .cs-upload_btn {
    background: #494F6A;
    border-radius: 10px;

  }

  .cs-delete_btn i {
    color: #494F6A;
  }

  .cs-btn {
    border-radius: 10px;
  }

  .profile_content {
    .cs-btn {
      float: right;
    }
  }

  .cs-form_label {
    margin-left: 0;
    color: #494F6A;
    font-size: 14px;
  }

  .cs-edit_profile {
    align-items: end;

    .cs-edit_profile_img {
      width: 176px;
      height: 176px;
    }
  }

  input:disabled,
  textarea:disabled {
    background: rgb(244, 244, 244);
  }
}