#header {
  .cs-tab_links {
    border-image-source: none;
    border-color: rgba(#7E85A1, .5);

    li {
      flex: 1;
      text-align: center;

      a {
        width: 100%;
        color: #96A0CB;

        .dage {
          background: #96A0CB;
          font-size: 14px;
          border-radius: 50px;
          color: white;
          padding: 3px 8px;
          margin: 0 4px;
        }

      }

      &.active {
        .txt {
          background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          background-clip: text;
          color: transparent;
        }

        .dage {
          background-color: #2B2B2B;
        }
      }
    }
  }

  .cs-tab {
    max-height: 500px;
    overflow: auto;
  }

  .cs-notice_box {
    .cs-toggle_body {
      width: 414px;
      background: white;
      border-radius: 10px;
      box-shadow: 0px 0px 12px 0px #23116C33;
      font-weight: normal;

      .notice-item {
        align-items: center;
        border-radius: 10px;
        margin: 18px;
        border: 1px solid #96a0cb66;
        padding: 12px;
        color: #7E85A1;

        .notice-icon {
          color: #7E85A1;

          img {
            width: 26px;
            height: 26px;
            display: block;
          }

          .active {
            display: none;
          }
        }

        .notice-data {
          margin-left: 12px;
          flex: 1;

          .time {
            font-size: 14px;
            color: #96A0CB;
          }
        }

        &:hover {
          border-color: transparent;
          box-shadow: 0px 0px 6px 0px #5A40FF40;

          .notice-data {
            color: #2B2B2B;
          }

          .notice-icon {
            .active {
              display: block;
            }

            .default {
              display: none;
            }
          }
        }
      }
    }
  }
}