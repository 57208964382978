.input-box {
  display: flex;
  align-items: center;
  border: 1px solid #96A0CB;
  border-radius: 10px;
  padding: 0 9px 0 13px;
  min-height: 54px;
  background-color: white;

  &.sm {
    min-height: 39px;
    font-size: 14px;
  }

  input {
    background: transparent;
    border: none;
    outline: none;
    flex: 1;
    font-size: 16px;
    text-align: inherit;
    min-width: 80px;
    width: 80px;

    &::-webkit-input-placeholder {
      color: #BABFD0;
    }
  }

  &.disabled {
    background-color: rgb(245, 245, 245);
  }

  .date-input {
    border: none;
    flex: 1;
    outline: none;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
  }
}



.b-select {
  --height: 54px;
  --list-height: 300px;
  height: var(--height);
  padding: 0 9px 0 13px;
  border-radius: 10px;
  position: relative;
  color: #050023;
  min-width: 100px;
  background-color: white;

  &.disabled {
    background-color: rgb(245, 245, 245);
  }

  &.sm {
    --height: 34px;

    .value-box {
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.blue-box {
    .content {
      border-color: #3772FF;
    }
  }

  .value-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .value {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      // height: 3em;
      // line-height: 1.5em;
    }

    .icon {
      width: 30px;
      height: 30px;
      transform: rotateZ(180deg);
      transition: all .3s;
    }

    .placeholder {
      color: #96A0CB;
    }
  }

  .content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: calc(var(--height) - 2px);
    border-radius: 10px;
    border: 1px solid #96A0CB;
    overflow: hidden;
    // transition: max-height .3s;
    // max-height: 0px;
    box-sizing: border-box;

    .select-list {
      background: white;
      transition: max-height .3s;
      position: relative;
      z-index: 9;
      overflow-y: auto;
      max-height: 0px;

      .select-group-label {
        padding: 8px 15px;
        padding-bottom: 8px;
        color: #96A0CB;
        font-size: 12px;
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(90deg, #96A0CB, #ffffff) 1;
      }

      .select-item {
        padding: 8px 15px;
        cursor: pointer;

        &:hover,
        &.acitve {
          color: #3772FF;
        }

        &:hover {
          background-color: rgba(#3772FF, .1);
        }
      }
    }
  }

  &.active {
    .value-box {
      .icon {
        transform: rotateZ(0);
      }
    }

    .content {
      .select-list {
        border-top: 1px solid #BABFD0;
        max-height: calc(var(--list-height) - var(--height));
      }

      // max-height: var(--list-height);

      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: var(--height);
      //   left: -1px;
      //   right: -1px;
      //   bottom: -1px;
      //   display: block;
      //   border-top: 0;
      //   border: 1px solid #7E85A1;
      //   border-bottom-left-radius: 10px;
      //   border-bottom-right-radius: 10px;
      // }
    }
  }
}