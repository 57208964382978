.patent-list {

  .btn-plain,
  .btn-entity {
    font-size: 16px;
    padding: 6px 18px;
    border: 0;
    border-radius: 10px;

    &:hover {
      background: linear-gradient(116.85deg, #fc466b 0%, #3f5efb 100%);

      &::after {
        background: linear-gradient(116.85deg, #fc466b 0%, #3f5efb 100%);
        border: 0;
      }

      span {
        color: white;
      }
    }
  }

  .btn-entity {
    color: white;
    background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);

    svg {
      fill: white;
    }
  }

  .btn-plain {
    background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
    position: relative;

    &::after {
      content: '';
      border-radius: inherit;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid transparent;
      background: white;
      background-clip: content-box;
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  .user-info {
    margin-top: -85px;
    position: relative;

    .avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-right: 28px;
      object-fit: cover;
    }

    .name {
      font-size: 32px;
      color: white;
      margin-bottom: 10px;
      font-weight: bold;
    }

    .tip {
      color: white;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: normal;
    }

    .flex-fill {
      .item {
        padding: 0 40px;

        &:first-child {
          padding-left: 0;
        }

        &:not(:last-child) {
          position: relative;

          &::after {
            content: '';
            display: block;
            border-right: 1px solid rgba(#1246C3, .5);
            position: absolute;
            right: 0;
            height: 22px;
            margin-top: -11px;
            top: 50%;
          }
        }

        .label {
          font-size: 16px;
          margin-right: 10px;
        }

        .val {
          font-size: 20px;
          font-weight: bold;
          color: #2B2B2B;

        }
      }

    }

    .notice {
      width: 40px;
      height: 40px;
      border: none;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: -1px -1px 5px rgba(#0479F7, .3),
        0px 0px 5px rgba(#5A40FF, .3),
        1px 1px 5px rgba(#2600BD, .3);
    }
  }

  .cs-tab_links {
    border-image-source: none;
    border-color: rgba(#7E85A1, .5);

    li {
      flex: 1;
      text-align: center;

      a {
        width: 100%;
        color: #96A0CB;

        .dage {
          background: #96A0CB;
          font-size: 14px;
          border-radius: 50px;
          color: white;
          padding: 3px 8px;
          margin: 0 4px;
        }

      }

      &.active {
        .txt {
          background: linear-gradient(90deg, #242424 36.5%, #3772FF 100%);
          background-clip: text;
          color: transparent;
        }

        .dage {
          background-color: #2B2B2B;
        }
      }
    }
  }
}