.home-page {
  .cs-hero {
    // background: url('./img/home-banner.jpg');
  }

  @media screen and (max-width: 768px) {
    background-color: white;

  }

  .home-boxes {

    .container {
      background: url(../img/boxesbg.png) center center/100% 100% no-repeat;
      padding-top: 52px;
      padding-bottom: 52px;
      position: relative;
      // &::before {
      //   position: absolute;
      //   content: url(../img/boxecenter.png);
      //   top: 20px;
      //   left: 50%;
      // }

      &::after {
        position: absolute;
        content: url(../img/boxesafter.png);
        bottom: -20px;
        right: -70px;
      }
    }

    h3 {
      font-size: 30px;

      .icon {
        width: 41px;
        height: 49px;
      }
    }

    .group {
      position: relative;
      z-index: 2;

      .item {
        font-size: 14px;
        align-items: center;
        margin: 15px 0;
        padding: 30px;
        background: rgba(255, 255, 255, .5);
        border-radius: 10px;
        flex: 1;

        .icon {
          width: 30px;
          height: 30px;
          margin-right: 30px;
        }

        .info {
          flex: 1;
          color: #2B2B2B;
          font-weight: 500;
        }
      }
    }

    @media screen and (max-width: 768px) {
      overflow: hidden;

      .container {
        background: none;

        &::after {
          content: none;
        }

        .title {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-size: 32px;
        }

        $p: 10px;

        .group {
          background: url('../img/home/CK_ti289a41709_01_1.png') no-repeat 0 0/auto,
          url('../img/home/CK_ti289a41712_03_1.png') no-repeat right 0 bottom 200px / auto,
          linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 40.33%),
          url('../img/home/home_boxs_bg.png') no-repeat #{$p} #{$p} / calc(100% - #{$p*2}) calc(100% - #{$p*2});

          .item {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 28px;

            .icon {
              width: 20px;
              height: 20px;
              margin-right: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }



  .category-box {
    padding: 0 !important;
    overflow: hidden;

    &:hover {
      box-shadow: 0px 0px 12px 0px #8B9AD04D;
    }

    .img-box {
      width: 64px;
      height: 80px;
      position: relative;
      margin: 0;
      margin-right: 10px;

      &::after {
        content: '';
        display: block;
        width: 8px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      flex: 1;
      font-size: 12px;

      .top {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 20px;

        .name {
          font-size: 16px;
          color: black;
        }
      }

      .line {
        margin-top: 10px;
        margin-bottom: 6px;
        height: 2px;
        background: linear-gradient(90deg, #FFFFFF 0%, #1B2960 70.5%);
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 20px;
      }
    }
  }

  .card-1 {
    &:hover {
      box-shadow: 0px 0px 12px 0px #8B9AD04D;
    }

    &.cs-style4 .cs-card_btn_2 {
      background-color: #050023;
    }
  }

  .home-dialogs {
    position: fixed;
    z-index: 99999999;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dialog-contents {
      max-width: 338px;
      width: 100%;
      // height: 408px;
      padding-bottom: 36px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        max-width: 60px;
        width: 100%;
      }

      .dialog-title {
        font-weight: 700;
        color: #2b2b2b;
        font-size: 26px;
        margin: 30px 0 10px 0
      }

      .dialog-desc {
        color: #2b2b2b;
        font-size: 16px;
        text-align: center;
        line-height: 22.4px;
      }

      .cs-btn.cs-style1 {
        width: 100%;
        max-width: 279px;
        height: 46px;
        text-align: center;
        line-height: 2em;
        margin-top: 2em
      }
    }
  }
}